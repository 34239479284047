

















































import { Component, Vue } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import Validation from "@/components/Validation.vue";
import Datepicker from "@/components/Datepicker.vue";
import { IBasicNamedDTO } from "@/interfaces/IBasicNamedDTO";
import { PAYMENT_TYPES } from "@/constants/PaymentType";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import { downloadFile } from "@/utils/File";
import PrintButton from "@/components/Button/PrintButton.vue";
import { namespace } from "vuex-class";

const ModuleExport = namespace("report");
@Component({
  components: { PrintButton, Datepicker, Validation, FscSimpleCard },
})
export default class ExportStudentPayments extends Vue {
  public name = "ExportStudentPayments";

  public paymentTypes: Array<number> = [];
  public fileType = "csv";
  public fromDate = null;
  public toDate = null;

  public get paymentTypesOptions(): Array<IBasicNamedDTO> {
    return PAYMENT_TYPES;
  }

  private filesTypesOptions = [
    { text: "CSV", value: "csv" },
    { text: "PDF", value: "pdf" },
  ];

  @ModuleExport.Mutation("export/IS_LOADING")
  protected setIsLoading: any;

  @ModuleExport.Getter("export/getIsLoading")
  protected getIsLoading: any;

  @Validations()
  public validations(): Record<string, any> {
    return {
      fromDate: {
        required,
      },
      toDate: {
        required,
      },
      paymentTypes: {
        required,
      },
    };
  }

  public onSubmit(): void {
    this.$v.$touch();
    if (this.$v.$invalid) return;

    this.setIsLoading(true);
    downloadFile(
      {
        url: `/reports/students-payments/${this.fileType}`,
        method: "post",
        data: {
          fromDate: this.fromDate,
          toDate: this.toDate,
          paymentTypeId: this.paymentTypes,
        },
      },
      `export.${this.fileType}`
    )
      .then((res: any) => {
        this.setIsLoading(false);
      })
      .catch((er: any) => {
        this.setIsLoading(false);
      });
  }
}
