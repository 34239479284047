import { IBasicNamedDTO } from "@/interfaces/IBasicNamedDTO";

export const PARTIAL_INVOICE = 100;
export const FINAL_INVOICE = 200;
export const CANCELLATION_INVOICE = 300;
export const COST_BEARER_INVOICE = 400;

export const PAYMENT_TYPE_CASH = 1;
export const PAYMENT_TYPE_BANK = 2;

export const INVOICE_TYPES_WITHOUT_COST_BEARER: Array<IBasicNamedDTO> = [
  {
    id: PARTIAL_INVOICE,
    name: "AZR",
  },
  {
    id: FINAL_INVOICE,
    name: "EAR",
  },
  {
    id: CANCELLATION_INVOICE,
    name: "Storno-Rechnung",
  },
];

export const INVOICE_TYPES: Array<IBasicNamedDTO> = [
  {
    id: PARTIAL_INVOICE,
    name: "AZR",
  },
  {
    id: FINAL_INVOICE,
    name: "EAR",
  },
  {
    id: CANCELLATION_INVOICE,
    name: "Storno-Rechnung",
  },
  {
    id: COST_BEARER_INVOICE,
    name: "Kostenträger-Rechnung",
  },
];
export const PAYMENT_TYPES: Array<IBasicNamedDTO> = [
  {
    id: PAYMENT_TYPE_CASH,
    name: "Cash",
  },
  {
    id: PAYMENT_TYPE_BANK,
    name: "Bank",
  },
];

export const INVOICE_TYPES_WITHOUT_CANCELLATION_INVOICE: Array<IBasicNamedDTO> = [
  {
    id: PARTIAL_INVOICE,
    name: "AZR",
  },
  {
    id: FINAL_INVOICE,
    name: "EAR",
  },
  {
    id: COST_BEARER_INVOICE,
    name: "Kostenträger-Rechnung",
  },
];
