
























import { Component, Vue } from "vue-property-decorator";
import FscPageHeader from "@/components/FscPageHeader.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import ReportEducations from "@/views/Report/ReportEducations.vue";
import { IBasicNamedDTO } from "@/interfaces/IBasicNamedDTO";
import ExportStudentsInvoices from "@/views/Report/ExportStudentsInvoices.vue";
import ExportStudentsBalanceOverview from "@/views/Report/ExportStudentsBalanceOverview.vue";
import ExportPdfInvoice from "@/views/Report/ExportPdfInvoice.vue";
import ExportStudentPayments from "@/views/Report/ExportStudentPayments.vue";
import ExportServiceList from "@/views/Report/ExportServiceList.vue";
import ExportBookingCompensation from "@/views/Report/ExportBookingCompensation.vue";

const STUDENT_BALANCE_OVERVIEW = 1;
const STUDENTS_INVOICES = 2;
const PDF_INVOICES = 3;
const EXPORT_STUDENT_PAYMENTS = 4;
const EXPORT_SERVICE_LIST = 5;
const EXPORT_BOOKING_COMPENSATION = 6;

@Component({
  components: {
    ExportBookingCompensation,
    ExportStudentsBalanceOverview,
    ExportStudentsInvoices,
    ReportEducations,
    FscSimpleCard,
    FscPageHeader,
    ExportPdfInvoice,
    ExportStudentPayments,
    ExportServiceList,
  },
})
export default class Export extends Vue {
  public name = "Export";

  public exportItems: Array<IBasicNamedDTO> = [
    {
      name: this.$t("report.opos").toString(),
      id: STUDENT_BALANCE_OVERVIEW,
    },
    {
      name: this.$t("report.invoices_list").toString(),
      id: STUDENTS_INVOICES,
    },
    {
      name: this.$t("report.invoices_pdf").toString(),
      id: PDF_INVOICES,
    },
    {
      name: this.$t("report.payments").toString(),
      id: EXPORT_STUDENT_PAYMENTS,
    },
    {
      name: this.$t("report.service_list").toString(),
      id: EXPORT_SERVICE_LIST,
    },
    {
      name: this.$t("report.booking_compensation").toString(),
      id: EXPORT_BOOKING_COMPENSATION,
    },
  ];

  public selectedRow: null | IBasicNamedDTO = null;

  public onSelectedRow(row: IBasicNamedDTO): void {
    this.selectedRow = row;
  }

  private get isStudentBalanceOverview() {
    return this.selectedRow && this.selectedRow.id == STUDENT_BALANCE_OVERVIEW;
  }

  private get isStudentsInvoices() {
    return this.selectedRow && this.selectedRow.id == STUDENTS_INVOICES;
  }

  private get isPdfInvoices() {
    return this.selectedRow && this.selectedRow.id == PDF_INVOICES;
  }

  private get isExportStudentPayments() {
    return this.selectedRow && this.selectedRow.id == EXPORT_STUDENT_PAYMENTS;
  }

  private get isServiceList() {
    return this.selectedRow && this.selectedRow.id == EXPORT_SERVICE_LIST;
  }

  private get isBookingCompensation() {
    return this.selectedRow && this.selectedRow.id == EXPORT_BOOKING_COMPENSATION;
  }
}
