

































import { Component, Vue } from "vue-property-decorator";
import Datepicker from "@/components/Datepicker.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import Validation from "@/components/Validation.vue";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import { downloadFile } from "@/utils/File";
import PrintButton from "@/components/Button/PrintButton.vue";
import { namespace } from "vuex-class";

const ModuleExport = namespace("report");
@Component({
  components: {
    Validation,
    Datepicker,
    FscSimpleCard,
    PrintButton,
  },
})
export default class ExportPdfInvoice extends Vue {
  public name = "ExportPdfInvoice.vue";
  @ModuleExport.Mutation("export/IS_LOADING")
  protected setIsLoading: any;

  @ModuleExport.Getter("export/getIsLoading")
  protected getIsLoading: any;

  public invoiceTypeOptions = [
    {
      id: 1,
      name: "AZR",
      type: "PARTIAL_INVOICE",
    },
    {
      id: 2,
      name: "EAR",
      type: "FINAL_INVOICE",
    },
    {
      id: 3,
      name: "Storno-Rechnung",
      type: "CANCELLATION_INVOICE",
    },
    {
      id: 4,
      name: "Kostenträger-Rechnung",
      type: "COST_BEARER_INVOICE",
    },
  ];

  public selectedInvoiceTypes: any = null;
  public fromDate: any = null;
  public toDate: any = null;

  protected onSubmit(): void {
    this.$v.$touch();
    if (this.$v.$invalid) return;

    this.setIsLoading(true);
    downloadFile(
      {
        url: `/reports/invoices`,
        method: "post",
        data: {
          fromDate: this.fromDate,
          toDate: this.toDate,
          invoiceTypes: this.selectedInvoiceTypes ? this.selectedInvoiceTypes : null,
        },
      },
      `invoices.zip`
    )
      .then((res: any) => {
        this.setIsLoading(false);
      })
      .catch((e: any) => {
        this.setIsLoading(false);
      });
  }

  @Validations()
  public validations(): Record<string, any> {
    return {
      fromDate: { required },
      toDate: { required },
    };
  }
}
